import React from "react";
import logo from '../images/edlogo.png'
import banner from '../images/hero-bg.jpg'
import about from '../images/about-img.jpg'
import '../components/component.css';
import { FloatingWhatsApp } from "react-floating-whatsapp";
import genie from '../images/genie.png'

function About() {
  return (

    <>
      <div className="hero_area">
        {/* <!-- header section starts --> */}
        <div className="hero_bg_box">
          <div className="img-box">
            <img src={banner} alt="" />
          </div>
        </div>
        <FloatingWhatsApp
          phoneNumber="+919743984846"
          accountName="Genie Support"
          avatar={genie}
          statusMessage="Typically replies within 1 hour"
          chatMessage="Hi there! How can I help you?"
          placeholder="Type your message..."
        />
        <header className="header_section">
          <div className="header_bottom">
            <div className="container-fluid">
              <nav className="navbar navbar-expand-lg custom_nav-container">
                <a className="navbar-brand" href="/">
                  <span>
                    <img src={logo} alt=" logo" style={{ height: "100px", width: "200px", marginTop: "-6%", marginLeft: "-6%" }} />
                  </span>
                </a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span className=""></span>
                </button>

                <div className="collapse navbar-collapse ml-auto" id="navbarSupportedContent">
                  <ul className="navbar-nav  ">
                    <li className="nav-item">
                      <a className="nav-link" href="/">Home <span className="sr-only">(current)</span></a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/about"> About</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/services"> Services </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/contact">Contact us</a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </header>
        {/* <!-- end header section --> */}


        {/* //   <!-- about section --> */}

        <section className="about_section layout_padding">
          <div className="container">
            <div className="row">
              <div className="col-md-6 px-0">
                <div className="img_container">
                  <div className="img-box">
                    <img src={about} alt="" />
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-0">
                <div className="detail-box">
                  <div className="heading_container ">
                    <h2>
                      Who Are We?
                    </h2>
                  </div>
                  <p>
                    "Electronics Doctor" is a pioneering service provider specializing in comprehensive care for all household electronic appliances. With a commitment to excellence, we offer top-notch repair, maintenance, and servicing solutions for a wide array of devices, including air conditioning units, refrigerators, ovens, washing machines, and more.
                  </p>
                  <p>
                    Our company stands out for its dedication to restoring functionality and efficiency to your essential home electronics. We boast a team of highly skilled technicians adept at diagnosing issues and implementing effective solutions promptly. Whether it's a malfunctioning air conditioner on a scorching day or a fridge that needs urgent attention, our experts arrive equipped with the expertise and tools needed to address your appliance concerns.
                  </p>
                  <p>
                    At Electronics Doctor, customer satisfaction is paramount. We prioritize reliability, professionalism, and a customer-centric approach in every interaction. Our goal is not only to fix the immediate problem but also to ensure the longevity and optimal performance of your electronics through preventive maintenance and quality service.
                  </p>
                  <p>
                    We understand the inconvenience of having household appliances malfunction, which is why we strive to offer timely and efficient services, aiming to minimize disruptions to your daily routine. With a focus on quality craftsmanship and a commitment to excellence, Electronics Doctor is your trusted partner in keeping your home appliances in top working condition.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <script src="src\jquery-3.4.1.min.js"></script>
      <script src="src\bootstrap.js"></script>
      <script src="src\custom.js"></script>
    </>

  )

};
export default About;
import React from "react";
import '../components/component.css';
import logo from '../images/edlogo.png';

function Footer() {
    return (
        <div >
            <section className="info_section " >
                <div className="container">
                    <div className="row" style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                        <div className="col-md-3">
                            <div className="info_logo">
                                <a className="navbar-brand" href="/">
                                    <span>
                                        <img src={logo} alt=" logo" style={{ height: "100px", width: "200px", marginTop: "-8%" }} />
                                    </span>
                                </a>
                                <p style={{ marginLeft: "5%", marginTop: "5%" }}>
                                    Providing Electronics households and equipments service at its best.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="info_info">
                                <h5>
                                    Contact Us
                                </h5>
                            </div>
                            <div className="info_contact">
                                <i className="fa fa-map-marker" aria-hidden="true"></i>
                                <span>
                                    Bangalore India,
                                </span>
                                <br></br>
                                <i className="fa fa-phone" aria-hidden="true"></i>
                                <span>
                                    Call : +91 9743984846
                                </span>
                                <br></br>
                                <i className="fa fa-envelope" aria-hidden="true"></i>
                                <span>
                                    electronicsdoctor.tech@gmail.com
                                </span>
                                <br></br>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="info_form ">
                                <h5>
                                    Social media handles
                                </h5>
                                <div className="social_box">
                                    <a href="https://www.facebook.com/electronics.doctor.2024/">
                                        <i className="bi bi-facebook" aria-hidden="true"></i>
                                    </a>
                                    <a href="https://api.whatsapp.com/send?phone=+919743984846">
                                        <i className="bi bi-whatsapp" aria-hidden="true"></i>
                                    </a>
                                    <a href="https://www.instagram.com/electronicsdoctor.tech">
                                        <i className="bi bi-instagram" aria-hidden="true"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <footer className="container-fluid footer_section">
                <p>
                    &copy; <span id="currentYear"></span> All Rights Reserved. Design by electronicsdoctor
                </p>
            </footer>
        </div>
    );

}

export default Footer;
import React, { useState } from 'react';
import contact from '../images/contact-bg.jpg';
import logo from '../images/edlogo.png';
import banner from '../images/hero-bg.jpg';
import '../components/component.css';
import { FloatingWhatsApp } from "react-floating-whatsapp";
import genie from '../images/genie.png'

function Contact() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        address: '',
        product: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const recipientEmail = "electronicsdoctor.tech@gmail.com";
        const subject = `Customer Query from ${formData.name}`;
        const body = `
            Name: ${formData.name}
            Email: ${formData.email}
            Phone Number: ${formData.mobile}
            Address: ${formData.address}
            Product: ${formData.product}
            Message: ${formData.message}
        `;
        const mailtoLink = `mailto:${recipientEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        window.location.href = mailtoLink;
        setFormData({
            name: '',
            email: '',
            mobile: '',
            address: '',
            product: '',
            message: ''
        });
    };
    const handleWhatsApp = (e) => {
        e.preventDefault();
        const phone = "+919743984846";
        const whatsappMessage = `
            Full Name: ${formData.name}
            Email: ${formData.email}
            Phone Number: ${formData.mobile}
            Address: ${formData.address}
            Product: ${formData.product}
            Message: ${formData.message}
        `;
        const whatsappLink = `https://api.whatsapp.com/send?phone=${phone}&text=${encodeURIComponent(whatsappMessage)}`;
        window.open(whatsappLink, "_blank");

        // Reset the form fields
        setFormData({
            name: '',
            email: '',
            mobile: '',
            address: '',
            product: '',
            message: ''
        });
    };

    return (
        <>
            <div className="hero_area">
                {/* <!-- header section starts --> */}
                <div className="hero_bg_box">
                    <div className="img-box">
                        <img src={banner} alt="" />
                    </div>
                </div>
                <FloatingWhatsApp
                    phoneNumber="+919743984846"
                    accountName="Genie Support"
                    avatar={genie}
                    statusMessage="Typically replies within 1 hour"
                    chatMessage="Hi there! How can I help you?"
                    placeholder="Type your message..."
                />
                <header className="header_section">
                    <div className="header_bottom">
                        <div className="container-fluid">
                            <nav className="navbar navbar-expand-lg custom_nav-container">
                                <a className="navbar-brand" href="/">
                                    <span>
                                        <img src={logo} alt="logo" style={{ height: "100px", width: "200px", marginTop: "-6%", marginLeft: "-6%" }} />
                                    </span>
                                </a>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>

                                <div className="collapse navbar-collapse ml-auto" id="navbarSupportedContent">
                                    <ul className="navbar-nav">
                                        <li className="nav-item">
                                            <a className="nav-link" href="/">Home<span className="sr-only">(current)</span></a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="/about">About</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="/services">Services</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="/contact">Contact us</a>
                                        </li>
                                    </ul>
                                </div>
                            </nav>


                        </div>
                    </div>
                </header>
                <section className="contact_section layout_padding">
                    <div className="contact_bg_box">
                        <div className="img-box">
                            <img src={contact} alt="" />
                        </div>
                    </div>
                    <div className="container">
                        <div className="heading_container heading_center">
                            <h2>
                                Get In touch
                            </h2>
                        </div>
                        <div className="">
                            <div className="row">
                                <div className="col-md-7 mx-auto">
                                    <form onSubmit={handleSubmit}>
                                        <div className="contact_form-container">
                                            <div>
                                                <div>
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        placeholder="Full Name"
                                                        value={formData.name}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </div>
                                                <div>
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        placeholder="Email"
                                                        value={formData.email}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </div>
                                                <div>
                                                    <input
                                                        type="text"
                                                        name="mobile"
                                                        placeholder="Phone Number"
                                                        value={formData.mobile}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </div>
                                                <div>
                                                    <input
                                                        type="text"
                                                        name="address"
                                                        placeholder="Address"
                                                        value={formData.address}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </div>
                                                <div>
                                                    <select
                                                        name="product"
                                                        value={formData.product}
                                                        onChange={handleChange}
                                                        style={{
                                                            marginTop: "3.5%",
                                                            color: "black",
                                                            border: "none",
                                                            width: "100%",
                                                            padding: "5px"
                                                        }}
                                                    >
                                                        <option value="" disabled>Select an item</option>
                                                        <option value="Refrigerator">Refrigerator</option>
                                                        <option value="Air conditioner">Air conditioner</option>
                                                        <option value="Washing machine">Washing machine</option>
                                                        <option value="Microwave Ovens">Microwave Ovens</option>
                                                        <option value="Smart TV">Smart TV</option>
                                                        <option value="Coolers">Coolers</option>
                                                        <option value="Mixer Grinders">Mixer Grinders</option>
                                                        <option value="Chimneys">Chimneys</option>
                                                        <option value="Others">Others</option>
                                                    </select>
                                                </div>
                                                <div>
                                                    <textarea
                                                        type="text"
                                                        name="message"
                                                        placeholder="Describe the problem"
                                                        cols="88"
                                                        rows="3"
                                                        value={formData.message}
                                                        onChange={handleChange}
                                                        style={{
                                                            marginTop: "3.5%",
                                                            color: "black",
                                                            border: "none",
                                                            width: "100%"
                                                        }}
                                                        required
                                                    />
                                                </div>
                                                <div className="btn-box ">
                                                    <button type="submit">
                                                        Send
                                                    </button>
                                                    <button type="submit" onClick={handleWhatsApp} style={{ marginLeft: '10px' }}>
                                                        Send via WhatsApp
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>


            </div>
            <script src="src\jquery-3.4.1.min.js"></script>
            <script src="src\bootstrap.js"></script>
            <script src="src\custom.js"></script>
        </>
    )
}

export default Contact;
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import { Route, Routes } from 'react-router-dom';
import Home from './screens/Home';
import About from './screens/About';
import Service from './screens/Service';
import Contact from './screens/Contact';
import logo from './images/edlogo.png';

function App() {
  return (
    <>
    <Header/>
    <Routes>
        <Route exact path="/" Component={Home} />
        <Route exact path="/about" Component={About} />
        <Route exact path="/services" Component={Service} />
        <Route exact path="/contact" Component={Contact} />  
        <Route exact path='/edlogo.png' Component={logo} />     
    </Routes>
    <Footer/>
    </>
  );
}

export default App;

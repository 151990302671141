import React from "react";
import '../components/component.css';

function Header() {

  return (
    <>
      <header className="header_section">
        <div className="header_top">
          <div className="container-fluid">
            <div className="contact_link-container">
              <a href="https://maps.app.goo.gl/5QEGpfnFHwVi8xAHA" className="contact_link1">
                <i className="bi bi-geo-alt-fill" aria-hidden="true"></i>
                <span>
                  Bangalore, India
                </span>
              </a>
              <a href="tel:+919743984846" className="contact_link2">
                <i className="bi bi-telephone-fill" aria-hidden="true"></i>
                <span>+91 9743984846</span>
              </a>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
